<template>
  <div
    class="HakunaSubtotal mt24 mb24"
    align="right"
  >
    <h1 style="color:#232323">Subtotal&nbsp;<strong>{{$nF(subtotal)}}</strong></h1>
    <br>
  </div>
</template>
<script>
export default {
  name: "HakunaSubtotal",
  props: {
    subtotal: [String, Number],
  },
};
</script>