import { Preferences } from "@capacitor/preferences";
export default {
    data() {
        return {
            cargando: false,
            search: "",
            showModalNextAction: false
        }
    },
    methods: {
        _handleError(err) {
            this.$emit("httpError", err);
            this.cargando = false;
        },
    },
    async created() {
        const a = await Preferences.get({ key: 'nombre' });
        const { value } = await Preferences.get({ key: 'token' });
        !value ?
            this.$router.push("/login") :
            this.$emit("navigation", true);
        if (this.$route.name == "CuentaForm" && this.$route.params.id) this.$emit("navigation", false);
    }
}