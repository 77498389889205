import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import { Geolocation } from "@capacitor/geolocation";
import HakunaSucursal from "@/components/lists/HakunaSucursal.vue";
export default {
  components: { HakunaSucursal },
  mixins: [standard, forms],
  data() {
    return {
      sucursales: [],
      latitude: 28.6175399,
      longitude: -106.0887361,
    };
  },
  methods: {
    _getSucursales({ coords }) {
      const { latitude, longitude } = coords;
      this.latitude = latitude;
      this.longitude = longitude;
      this._getThings(
        "sucursales?latitude=" + latitude + "&longitude=" + latitude,
        "sucursales"
      );
    },
    async _getSucursalesLocation() {
      try {
        console.log("getting sucursales coords");
        const coordinates = await Geolocation.getCurrentPosition();
        const { latitude, longitude } = coordinates.coords;
        //si el usuario no comparte su ubicación se mandará a login
        if (latitude == 0 || longitude == 0) {
          this.showDialog = false;
        }
        this.latitude = latitude;
        this.longitude = longitude;
        console.log(this.latitude, this.longitude);
      } catch (e) {
        //si el usuario no comparte su ubicación se mandará a login
        this.$router.push("/");
        this.showDialog = false;
      }
      this._getThings(
        "sucursales?latitude=" + this.latitude + "&longitude=" + this.longitude,
        "sucursales"
      );
    },
    _sucursalSeleccionada(suc) {},
  },
};
