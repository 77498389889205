<template>
  <div class="Home">
    <div clas="fixed fixed-top bg-white">
      <v-card-title>¡Hoy cumple tu antojo!</v-card-title>
      <v-card-subtitle @click="showDialogSucursal=true">
        <strong v-if="sucursal">Recogerás tu pedido en sucursal {{ sucursal }}</strong>
        <strong v-else>Compartenos tu ubicación y selecciona la ubicación donde recogerás tu pedido</strong>
        <v-btn
          small
          icon
        >
          <v-icon small>edit</v-icon>
        </v-btn>
      </v-card-subtitle>
    </div>
    <orden-menu
      style="margin-top:-60px"
      search-text="Busca tu bola de arroz preferida"
      :prioridades="prioridades"
      :actualizar="updateNeeded"
      @actualizado="updateNeeded=false"
      @click="idMen = menu.id"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @loading="$emit('loading',$event)"
      :hideSelect="sucursal?true:false"
    />
    <orden-sucursal-dialog
      v-model="showDialogSucursal"
      @change="_handleSucursalUpdate"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
      @loading="$emit('loading',$event)"
    />
  </div>
</template>
<script>
import OrdenMenu from "@/components/orden/OrdenMenu.vue";
import OrdenSucursalDialog from "@/components/orden/OrdenSucursalDialog.vue";
import forms from "@/mixins/forms.js";
import { Preferences } from "@capacitor/preferences";
export default {
  name: "Home",
  components: { OrdenMenu, OrdenSucursalDialog },
  mixins: [forms],
  data() {
    return {
      prioridades: "",
      distancia: "1",
      showDialogSucursal: false,
      sucursal: "",
      sucursalID: null,
      updateNeeded: false,
    };
  },
  async mounted() {
    this._getSucursalSeleccionada(true);
    this.prioridades = ["1", "2", "3", "4", "5"].toString();
    this.$emit("navigation", true);
  },
  methods: {
    async _getSucursalSeleccionada(openDialog = false) {
      const { value } = await Preferences.get({ key: "sucursal" });
      const sucursal = await Preferences.get({ key: "sucursalNombre" });
      this.sucursalID = value;
      this.sucursal = sucursal.value;
      if (!value && openDialog) this.showDialogSucursal = true;
    },
    async _handleSucursalUpdate() {
      this.updateNeeded = true;
      const { value } = await Preferences.get({ key: "sucursal" });
      const sucursal = await Preferences.get({ key: "sucursalNombre" });
      this.sucursalID = value;
      this.sucursal = sucursal.value;
      this._checkAvailabilityInCart(value);
    },
    _checkAvailabilityInCart(sucursal) {
      this.$http.post(
        "carritosucursal/" + sucursal,
        { sucursal: sucursal },
        this.$headers
      );
    },
  },
};
</script>