<template >
  <v-list-item
    class="HakunaListItem"
    two-line
    @click="$emit('click',true)"
    :ripple="click"
  >
    <!-- <v-list-item-icon v-if="icon">
      <img src="@/assets/icons/bola1.svg" />
    </v-list-item-icon> -->
    <v-list-item-avatar
      style="width:64px!important;height:64px!important; border-radius:16px!important;"
      v-if="imagen && !hideImg"
    >
      <v-img :src="imagen" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="nowrap"
        v-html="title"
      />
      <v-list-item-title
        v-if="subtitle"
        class="nowrap"
        v-html="subtitle"
      />
      <v-list-item-title
        v-if="thirdtitle"
        class="nowrap"
        v-html="thirdtitle"
      />
    </v-list-item-content>
    <!-- <v-list-item-action> <v-list-item-title style="align:right!important">$000</v-list-item-title> </v-list-item-action> -->
    <v-list-item-action v-if="button">
      <v-icon
        large
        color="secondary"
      >chevron_right</v-icon>
    </v-list-item-action>
    <v-list-item-action
      v-if="actions"
      style="padding-left:8px;text-align:right!important"
    >
      <div class="mt8">
        <v-btn
          @click="$emit('remove',true)"
          style="margin-left:12px"
          icon
          color="primary"
          outlined
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-btn
          @click="$emit('add',true)"
          style="margin-left:12px"
          icon
          color="primary"
          outlined
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: "HakunaListItem",
  props: {
    actions: Boolean,
    button: Boolean,
    click: Boolean,
    hideImg: Boolean,
    hideSelect: Boolean,
    icon: String,
    imagen: String,
    title: String,
    subtitle: String,
    thirdtitle: String,
    to: String,
  },
};
</script>