import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/sesion/Login.vue')
  }, {
    //aqui se ve todo lo que puede comprar el cliente
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  //cuenta
  {
    path: '/cuenta/:id?',
    name: 'CuentaForm',
    component: () => import('@/views/cuenta/CuentaForm.vue')
  },
  //carrito de compra
  {
    path: '/orden/resumen/:step?',
    name: 'OrdenResumen',
    component: () => import('@/views/orden/OrdenResumen.vue')
  },
  //extras como bebidas
  {
    path: '/orden/adicional',
    name: 'OrdenAdicional',
    component: () => import('@/views/orden/OrdenAdicional.vue')
  },
  //en donde recogerá la bola de arroz
  {
    path: '/orden/ubicacion',
    name: 'OrdenSucursal',
    component: () => import('@/views/orden/OrdenSucursal.vue')
  },
  //ordenes e informacion de la orden
  {
    path: '/ordenes',
    name: 'OrdenListado',
    component: () => import('@/views/orden/OrdenesListado.vue')
  },
  {
    path: '/orden/detalle/:idOrd/:estatus?',
    name: 'OrdenDetalle',
    component: () => import('@/views/orden/OrdenDetalle.vue')
  },
  //sucursales hakuna
  {
    path: '/sucursales',
    name: 'SucursalesView',
    component: () => import('@/views/sucursales/SucursalesView.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
