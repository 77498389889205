<template>
  <div>
    <v-dialog
      fullscreen
      v-model="showDialog"
    >
      <v-card :loading="cargando">
        <v-card-title>¿En cuál sucursal recogerás tu pedido?</v-card-title>
        <v-list
          rounded
          v-if="!cargando"
        >
          <v-list-item-group color="secondary">
            <div
              v-for="(sucursal,idxs) in sucursales"
              :key="sucursal.id_suc"
              style="margin-top:8px"
            >
              <v-list-item
                @click="sucursalSeleccionada = sucursal.id_suc"
                :class="sucursalSeleccionada==sucursal.id_suc?'v-item--active v-list-item--active':''"
              >
                <v-icon
                  color="secondary"
                  x-large
                >
                  place
                </v-icon>
                <v-list-item-content style="margin-left:8px">
                  <v-list-item-title class="nowrap">
                    <strong>{{ sucursal.nombre_suc }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle class="nowrap">
                    {{_doDomicilio(sucursal.gmaps_suc)}}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="nowrap"
                    v-if="sucursal.km"
                  >
                    A {{ sucursal.km }} de ti
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="idxs+1<sucursales.length" />
            </div>
          </v-list-item-group>
        </v-list>
        <v-skeleton-loader
          v-else
          type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line"
        />
        <!-- <v-list><div v-for="(sc,idxs) in sucursales" :key="idxs" ><hakuna-sucursal :sucursal="sc.nombre_suc" :distancia="sc.km" :domicilio="sc.nombre_suc" icon open @click="_doRedirectSucursal(sc)" /><v-divider v-if="idxs+1<sucursales.length" /></div></v-list>-->
        <v-card-actions v-if="sucursalSeleccionada && sucursalSeleccionada!=sucursalOriginal">
          <v-btn
            @click="_saveNuevaSucursal"
            block
            color="primary"
            rounded
            x-large
          >Confirmar selección</v-btn>
        </v-card-actions>
        <v-card-actions v-if="sucursalOriginal">
          <v-btn
            block
            rounded
            x-large
            text
            outlined
            @click="showDialog=false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      v-model="showDialogBegForLocation"
    >
      <v-card>
        <v-card-title>Compártenos tu ubicación</v-card-title>
        <v-card-text v-if="locationGranted">
          Al permitirnos conocer tu ubicación podremos mostrarte las sucursales Hakuna más cercanas a tí y brindarte una mejor experiencia.<br><br>
          Es importante informarte, si decides no compartir tu ubicación no será posible ordernar en nuestra app.
          <br><br><br>
          <v-btn
            block
            class="mb24"
            x-large
            rounded
            color="primary"
            flat
            @click="showDialog=true;"
          >
            Aceptar
          </v-btn>
          <br>
          <v-btn
            block
            text
            @click="showDialogBegForLocation=false;"
            rounded
          >
            Cancelar
          </v-btn>
        </v-card-text>
        <v-card-text v-else>
          Para permitirnos acceder a tu ubicación es necesario ir a ajustes de tu dispositivo y habilitar compartir tu ubicación a Hakunapp
          <v-btn
            block
            color="primary"
            @click="_nativeSettings()"
          >Abrir ajustes</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import sucursales from "@/mixins/sucursales.js";
import { Preferences } from "@capacitor/preferences";
import { Geolocation } from "@capacitor/geolocation";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";

export default {
  name: "OrdenSucursalDialog",
  mixins: [sucursales],
  props: {
    value: Boolean,
  },
  data() {
    return {
      locationGranted: true,
      showDialogBegForLocation: false,
      showDialog: false,
      sucursalSeleccionada: null,
      sucursalOriginal: null,
    };
  },
  watch: {
    value(a) {
      this._handleValueChange(a);
    },
    showDialog(a) {
      if (a) {
        this._getSucursalesLocationDialog();
        this._getSucursalActual();
      } else {
        this.showDialogBegForLocation = false;
        this.$emit("input", a);
      }
    },
    showDialogBegForLocation(a) {
      // if (!a && !this.showDialog) this.$emit("input", false);
    },
  },
  methods: {
    async _nativeSettings() {
      NativeSettings.open({
        optionAndroid: AndroidSettings.Application,
        optionIOS: IOSSettings.App,
      });
    },
    async _handleValueChange(value) {
      const { location } = await Geolocation.checkPermissions();
      console.log(location);
      if (value)
        switch (location) {
          case "granted":
            this.locationGranted = true;
            this.showDialogBegForLocation = false;
            this.showDialog = true;
            break;
          case "denied":
            this.locationGranted = false;
            this.showDialogBegForLocation = true;
            this.showDialog = false;
            break;
          case "prompt":
          case "prompt-with-rationale":
            this.locationGranted = true;
            this.showDialogBegForLocation = true;
            this.showDialog = false;
            this.$emit("input", false);
            break;
        }

      // if (value) this.showDialogBegForLocation = value;
    },
    async _getSucursalActual() {
      const { value } = await Preferences.get({ key: "sucursal" });
      this.sucursalOriginal = value;
      this.sucursalSeleccionada = value;
    },
    async _saveNuevaSucursal() {
      const id_suc = this.sucursalSeleccionada;
      await Preferences.set({
        key: "sucursal",
        value: id_suc,
      });
      let i = this.sucursales.findIndex(function (b) {
        return b.id_suc === id_suc;
      });
      this.$emit("change", true);
      if (i >= 0) {
        const nombre = this.sucursales[i]["nombre_suc"];
        await Preferences.set({
          key: "sucursalNombre",
          value: nombre,
        });

        this.$emit(
          "msg",
          "Se ha guardado la sucursal " + nombre + " para recoger tu pedido"
        );
      }
      this.showDialog = false;
    },
    _doDomicilio(string = "") {
      try {
        return JSON.parse(string).place_details.formatted_address;
      } catch (e) {
        return null;
      }
    },
    async _getSucursalesLocationDialog() {
      try {
        this.cargando = true;
        const coordinates = await Geolocation.getCurrentPosition();
        const { latitude, longitude } = coordinates.coords;
        //si el usuario no comparte su ubicación se mandará a login
        if (latitude == 0 || longitude == 0) {
          this.showDialogBegForLocation = false;
          this.showDialog = false;
          this.cargando = false;
        }
        this.latitude = latitude;
        this.longitude = longitude;
        this._getThings(
          "sucursales?latitude=" + latitude + "&longitude=" + longitude,
          "sucursales"
        );
      } catch (e) {
        //si el usuario no comparte su ubicación se mandará a login
        this.showDialogBegForLocation = false;
        this.showDialog = false;
      }
    },
  },
  created() {},
};
</script>