<template>
  <div>
    <v-app class="ion-page">
      <!-- <v-app-bar app color="white" fixed elevation="0" > <v-btn icon> <v-icon>menu</v-icon> </v-btn> </v-app-bar> -->
      <v-main class="mcontent">
        <v-card
          flat
          :loading="cargando"
          :disabled="cargando"
        >
          <vue-page-transition name="fade">
            <router-view
              @msg="_notify"
              @httpError="_resFail"
              @loadings="cargando=$event"
              @navigation="bottomNavigation=$event"
            />
          </vue-page-transition>
        </v-card>
      </v-main>
      <v-bottom-navigation
        v-if="bottomNavigation"
        exact
        class="secondary elevation-0"
        color="#fff"
        fixed
        grow
        style="padding-top: 8px;padding-bottom:8px; margin-bottom: 0px!important;position:fixed!important;bottom:0!important"
      >
        <v-btn
          v-for="(n,idxn) in navigation"
          color="secondary"
          :value="idxn"
          :key="idxn"
          :to="n.to"
        >
          <span v-text="n.text" />
          <v-icon v-text="n.icon" />
        </v-btn>
        <v-btn
          v-if="showCarrito"
          color="secondary"
          to="/orden/resumen/1"
        >
          <span v-text="'Tu orden'" />
          <v-icon v-text="'shopping_basket'" />
        </v-btn>
      </v-bottom-navigation>
      <v-snackbar
        class="fixed fixed-top"
        style="z-index:999!important;"
        v-model="snackbar.show"
        bottom
        :timeout="3000"
        rounded
        color="primary"
      >
        <v-btn
          text
          @click="snackbar.show = false"
          icon
          class="dark-icon"
        >
          <v-icon v-text="'close'" />
        </v-btn>
        {{ snackbar.text }}
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { SplashScreen } from "@capacitor/splash-screen";
import { Preferences } from "@capacitor/preferences";
// import { StatusBar, Style } from "@capacitor/status-bar";
// window.addEventListener("statusTap", function () {});
export default {
  name: "App",
  data() {
    return {
      cargando: false,
      snackbar: {
        show: false,
        text: "",
      },
      appClasses: "",
      showClasses: "",
      showCarrito: false,
      bottomNavigation: null,
      navigation: [
        { text: "Ordena", icon: "local_mall", to: "/" },
        { text: "Pedidos", icon: "receipt_long", to: "/ordenes" },
        { text: "Sucursales", icon: "place", to: "/sucursales" },
        { text: "Cuenta", icon: "face", to: "/cuenta" },
      ],
    };
  },
  methods: {
    _notify(a) {
      if (a) {
        this.snackbar.text = a;
        this.snackbar.show = true;
      }
    },
    _resFail(a) {
      if (a.status === 401) {
        this.$router.push("/login");
      } else if (a.status === 400) {
        this._notify(a.body.error);
      } else if (a.body.error) {
        this._notify(a.body.error);
      } else {
        this._notify(this.$noInternet);
      }
    },
    async _getCarrito() {
      try {
        const {
          body: { carrito },
        } = await this.$http.get("carrito", this.$headers);
        await SplashScreen.hide();
        this.showCarrito = carrito && carrito.length >= 1;
      } catch (err) {
        this.cargando = false;
        this.showCarrito = false;
        await SplashScreen.hide();
      }
    },
  },
  async created() {
    this.cargando = false;
    try {
      //generamos los headers para el token barrier
      const { value } = await Preferences.get({ key: "token" });
      this.$headers.headers.Token = value ? value : null;
      //checamos si el token es valido
      const { body } = await this.$http.get("hassession", this.$headers);
      //si la respuesta es positiva lo dejamos donde esta
      if ("session" in body && body["session"] == true) {
        this._getCarrito();
      } else {
        //si no tiensesión, lo mandamos a login
        this.$router.push("/login");
      }
    } catch (err) {
      this.$router.push("/login");
    }
  },
  async beforeCreate() {
    // await SplashScreen.show();
  },
  async mounted() {
    // await SplashScreen.hide();
    this.$EventBus.$on("cartUpdated", () => {
      this._getCarrito();
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("cartUpdated");
  },
};
</script>
<style lang="scss">
.v-main__wrap {
  position: none !important;
}
.v-main {
  flex: 1 0 !important;
}

.v-application--wrap {
  min-height: auto !important;
}
@import "./styles/app";
</style>