<template>
  <div>
    <div class="content">
      <v-card-text style="margin-bottom:-28px;">
        <v-text-field
          clearable
          filled
          :label="searchText?searchText:'Buscar'"
          :disabled="cargando"
          rounded
          prepend-inner-icon="search"
          :hint="$version"
          persistent-hint
          v-model="searchWord"
        />
      </v-card-text>
      <v-list v-if="!cargando">
        <div
          v-if="!searchWord"
          v-for="(categoria,idxc) in menu"
          :key="idxc"
        >
          <br v-if="idxc>=1">
          <v-subheader
            class="upper"
            style="font-weight: 700;"
            v-text="categoria.nombre_cat"
          />
          <div
            v-for="(menu,idxm) in categoria.menu"
            :key="idxm"
          >
            <hakuna-list-item
              :button="hideSelect"
              icon="icono"
              :title="menu.name_men"
              :subtitle="'<strong>'+$nF(menu.price_men)+'</strong>'"
              :imagen="menu.miniaturaImageUrl_men"
              :hideSelect="hideSelect"
              @click="hideSelect ? idMen = menu.id : null"
              @msg="$emit('msg',$event)"
              @httpError="$emit('httpError',$event)"
              @loading="$emit('loading',$event)"
            />
            <v-divider />
          </div>
        </div>
        <div
          v-if="searchWord && searchWord.length >= 1"
          v-for="(menu,idxfm) in filterMenu"
          :key="idxfm"
        >
          <hakuna-list-item
            :button="hideSelect"
            icon="icono"
            :title="menu.name_men"
            :subtitle="'<strong>'+$nF(menu.price_men)+'</strong>'"
            :imagen="menu.miniaturaImageUrl_men"
            @click="hideSelect ? idMen = menu.id : null"
            @msg="$emit('msg',$event)"
            @httpError="$emit('httpError',$event)"
            @loading="$emit('loading',$event)"
          />
          <v-divider />
        </div>
        <div
          v-if="searchWord && searchWord.length >= 1 && filterMenu.length<1"
          style="max-width: 300px;text-align: center;align-items: center;margin-left: auto!important;margin-right: auto!important;"
        >
          <v-img src="@/assets/illustrations/not-found.png" />
          <br>Ups! No hemos encontrado en nuestro menú algo parecido a <strong>{{ searchWord }}</strong>
          <br><br>
          <v-btn
            outlined
            color="secondary"
            rounded
            block
            @click="searchWord=null"
          >Limpiar búsqueda</v-btn>
        </div>
      </v-list>
      <v-skeleton-loader
        v-else
        type="list-item-avatar-two-line@8"
      />
    </div>
    <orden-01-configura
      v-model="idMen"
      @loading="$emit('loading',$event)"
      @msg="$emit('msg',$event)"
      @httpError="$emit('httpError',$event)"
    />
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import HakunaListItem from "@/components/lists/HakunaListItem.vue";
import Orden01Configura from "@/components/orden/Orden01Configura.vue";
import { Preferences } from "@capacitor/preferences";
export default {
  name: "OrdenMenu",
  mixins: [standard, forms],
  props: {
    searchText: String,
    prioridades: String,
    actualizar: Boolean,
    hideSelect: Boolean,
  },
  components: { HakunaListItem, Orden01Configura },
  data() {
    return {
      show: false,
      aviso: "",
      menu: [],
      filterMenu: [],
      idMen: null,
      searchWord: null,
    };
  },
  methods: {
    async _getMenu() {
      const { value } = await Preferences.get({ key: "sucursal" });
      this._getThings(
        "menu?sucursal=" + value + "&prioridades=" + this.prioridades,
        "menu"
      );
    },
  },
  mounted() {
    this._getMenu();
  },
  watch: {
    actualizar(a) {
      if (a) {
        this._getMenu();
        this.actualizar = false;
        this.$emit("actualizado", false);
      }
    },
    searchWord(lookingFor) {
      if (lookingFor && lookingFor.length >= 1) {
        try {
          const search = lookingFor.toLowerCase();
          const menu = Object.assign({}, this.menu);
          const items = [];
          Object.keys(menu).forEach((item) => {
            menu[item].menu.map((a) => {
              if (
                a.descripcion_men.toLowerCase().search(search) >= 0 ||
                a.name_men.toLowerCase().search(search) >= 0
              )
                items.push(a);
            });
          });
          this.filterMenu = items;
        } catch (e) {}
      }
    },
  },
};
</script>
<style lang="scss">
.fixed {
  width: 100%;
  z-index: 2;
  position: fixed;
}
.fixed-top {
  top: 0;
}
.fixed-bottom {
  bottom: 0 !important;
  position: absolute;
  overflow: hidden;
}
.content {
  overflow: hidden !important;
  padding: 66px 0;
  // height: calc(100vh - 180px) !important;
}
.bg-white {
  background-color: #fff !important;
}
</style>