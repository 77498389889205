const version = "0.0214.1006";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

import VueMask from "v-mask";
Vue.use(VueMask);

import VueSession from "vue-session";
Vue.use(VueSession, { persist: true });

import VuePageTransition from "vue-page-transition";
Vue.use(VuePageTransition);

//definimos headers para las peticiones
Vue.prototype.$headers = {
  headers: {
    Token: null,
    "Cache-Control": "no-cache",
  },
};
//resource manager & urls
import VueResource from "vue-resource";
Vue.use(VueResource);

// const api = "http://localhost:8888/hakuna/hakuna-api/app/";
const api = "https://api.hakuna.com.mx/app/";

Vue.http.options.root = api;
Vue.prototype.$api = api;
Vue.http.options.emulateJSON = true;

Vue.config.productionTip = false;

Vue.prototype.$version = version;
Vue.prototype.$nF = (n) => {
  return Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(n);
};

Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
