<template>
  <v-list-item
    class="HakunaSucursal"
    two-line
    @click="$emit('click',true)"
  >
    <v-list-item-icon v-if="icon">
      <v-icon
        color="secondary"
        x-large
      >
        place
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="nowrap">
        {{sucursal}}
      </v-list-item-title>
      <v-list-item-subtitle v-if="domicilio">
        {{domicilio}}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="nowrap"
        v-if="distancia"
      >
        {{distancia}}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="open || select">
      <v-btn
        icon
        color="secondary"
      >
        <v-icon
          x-large
          v-if="open"
        >open_in_new</v-icon>
        <v-icon
          x-large
          v-if="select"
        >chevron_right</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: "HakunaSucursal",
  props: {
    icon: Boolean,
    open: Boolean,
    select: Boolean,
    //textos
    sucursal: String,
    domicilio: String,
    distancia: String,
  },
};
</script>