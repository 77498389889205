<template>
  <div>
    <v-dialog
      fullscreen
      persistent
      v-model="showDialog"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>
          <span>
            <v-btn
              icon
              @click="showDialog=false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            Personaliza tu <span style="text-transform: capitalize;">{{menu.id ? menu.name_men.toLowerCase():'pedido'}}</span>
          </span>
        </v-card-title>
        <v-card-text v-if="!cargando">
          <v-img
            v-if="menu.imageUrl_men && menu.imageUrl_men!=''"
            class="rounded-xl"
            :src="menu.imageUrl_men"
            :lazy-src="menu.imageUrl_men"
            max-height="400"
          />
          <p
            class="mt8"
            v-if="menu.descripcion_men && menu.descripcion_men!=''"
          >{{menu.descripcion_men}}</p>
          <v-expansion-panels v-model="paneles">
            <v-expansion-panel v-if="ingredientesExtras.length>=1">
              <v-expansion-panel-header>
                <span
                  class="title"
                  v-text="'¿Deseas agregar algún ingrediente extra?'"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(ie,idxe) in ingredientesExtras"
                  :key="idxe"
                >
                  <v-switch
                    v-model="extras"
                    inset
                    :value="ie.id_men"
                  >
                    <template v-slot:label>
                      {{ie.name_men}}
                      <v-spacer />
                      <strong>+{{$nF(ie.price_men)}}</strong>
                    </template>
                  </v-switch>
                  <v-divider v-if="idxe+1 < ingredientesExtras.length" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="sinIngredientes.length>=1">
              <v-expansion-panel-header>
                <span
                  class="title"
                  v-text="'¿Removerás algo?'"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(si,idxs) in sinIngredientes"
                  :key="idxs"
                >
                  <v-switch
                    v-model="quitar"
                    inset
                    :value="si.id_men"
                  >
                    <template v-slot:label>
                      {{si.name_men}}
                    </template>
                  </v-switch>
                  <v-divider v-if="idxs+1 < sinIngredientes.length" />
                </div>

              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span
                  class="title"
                  v-text="'¿Algún comentario adicional?'"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea
                  filled
                  rounded
                  v-model="comentarios"
                  label="Comentarios adicionales"
                  rows="4"
                  prepend-inner-icon="chat"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider></v-divider>
          <v-row class="text-center mt24">
            <v-col>
              <v-btn
                icon
                outlined
                x-large
                color="primary"
                @click="cantidad--"
              >
                <v-icon>remove</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <h1
                class="align-self-center"
                style="margin-top: 8px;"
              >
                <strong>{{cantidad}}</strong><br><small>Cantidad</small>
              </h1>
            </v-col>
            <v-col>
              <v-btn
                icon
                outlined
                x-large
                color="primary"
                @click="cantidad++"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <hakuna-subtotal :subtotal="subtotal" />
          <v-btn
            block
            color="primary"
            class="mt24 mb24"
            depressed
            rounded
            x-large
            v-text="'Agregar al carrito'"
            @click="_addToCart"
          />
        </v-card-text>
        <v-card-text v-if="cargando">
          <v-skeleton-loader type="card,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,actions" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import HakunaSubtotal from "@/components/texts/HakunaSubtotal.vue";
import { Preferences } from "@capacitor/preferences";
export default {
  name: "Orden01Configura",
  props: {
    value: [String, Number],
    redirect: String,
  },
  components: { HakunaSubtotal },
  mixins: [forms, dates],
  data() {
    return {
      showDialog: false,
      menu: {},
      paneles: null,
      cantidad: 1,
      ingredientesExtras: [],
      sinIngredientes: [],
      extras: [],
      quitar: [],
      precios: [],
      comentarios: null,
      today: null,
    };
  },
  methods: {
    async _getMenu(id) {
      //id es la prop value que recibe el id del menu
      const { value } = await Preferences.get({ key: "sucursal" });
      this.showDialog = true;
      this.cargando = true;
      try {
        this.precios = [];
        const {
          body: { menu },
        } = await this.$http.get(
          "item/" + this.value + "?sucursal=" + value,
          this.$headers
        );
        this.menu = Object.assign({}, menu);
        if (menu.removibles.length >= 1) this.sinIngredientes = menu.removibles;
        if (menu.opciones.length >= 1) {
          this.ingredientesExtras = menu.opciones;
          this.precios = menu.opciones.reduce(
            (aa, bb) => ({ ...aa, [bb.id_men]: parseFloat(bb.price_men) }),
            {}
          );
        }
        this.cargando = false;
      } catch (err) {
        this.cargando = false;
        this.$emit(
          "msg",
          "Sucedió un error al obtener el producto seleccionado"
        );
        this.showDialog = false;
      }
    },
    _closeDialog() {
      this.value = "";
      this.$emit("input", "");
      this._limpiarCampos("menu");
      this.showDialog = false;
    },
    _addToCart() {
      this.cargando = true;
      const data = {
        carrito: {
          id_men_car: this.menu.id_men,
          cant_car: this.cantidad,
          price_car: this.subtotal / this.cantidad,
          subtotal_car: this.subtotal,
          extras_car: Object.assign({}, this.extras),
          quitar_car: Object.assign({}, this.quitar),
          comentarios_car: this.comentarios,
        },
      };
      this._saveAll("carrito", data, "id", "_handleCartAdded");
    },
    _handleCartAdded(res) {
      this.$EventBus.$emit("cartUpdated", Date.now());
      this.$emit("msg", res.msg);
      this.$emit("carrito", Date.now());
      this._closeDialog();
      if (!this.redirect || this.redirect != "no")
        this.$router.push("/orden/resumen/1");
    },
  },
  watch: {
    value(id) {
      this.today = this._today();
      id && id != "" ? this._getMenu() : this._closeDialog();
    },
  },
  computed: {
    subtotal() {
      var a = parseFloat(this.menu.price_men);
      this.extras.forEach((e) => {
        a += this.precios[e];
      });
      return a * parseFloat(this.cantidad);
    },
  },
};
</script>