import { DateTime, Interval } from "luxon";
export default {
    data() { return {} },
    methods: {
        _datetime() {
            return DateTime
                .now()
                .setZone("America/Chihuahua")
        },
        _today() {
            return this._datetime()
                .toISODate()
        },
        _isValidDate(fecha) {
            const res = DateTime
                .fromSQL(fecha)
                .isValid
            return res;
        },
        _dt(fecha) {
            return DateTime
                .fromSQL(fecha, { setZone: false })
                .setLocale('es-Mx');
        },
        _doHoras(fecha, meridiem = true) {
            return DateTime.fromSQL(fecha).toFormat(meridiem ? 't' : 'HH:mm');
        },
        _doEdad(fecha) {
            const fechaNac = this._dt(fecha);
            var { years } = DateTime.now().diff(fechaNac, "years").toObject();
            return Math.floor(years);
        },
        _doDiaMes(fecha) {
            if (!fecha) return ''
            return this._dt(fecha).toFormat('DDD');
        },
        _doDiaMesHoras(fecha, showDia = false) {
            if (!fecha) {
                return ''
            }
            const f = showDia ? 'DDDD' : 'DDD'
            return this._dt(fecha).toFormat(`${f} 'a las' t`);
        },
        _doMesAnio(fecha) {
            return this._dt(fecha).toFormat('MMMM yyyy')
        },
        _diffHours(start, end) {
            if (!start || !end) return null;
            const fecha = this._today();
            const i = DateTime.fromSQL(fecha + " " + start);
            const f = DateTime.fromSQL(fecha + " " + end)
            const addDays = i > f ? 1 : 0;
            const { hours } = f
                .plus({ days: addDays })
                .diff(i, "hours")
                .toObject();
            return Math.ceil(hours);
        },
        _diffDays(start, end) {
            if (!start || !end) return null;
            const i = DateTime.fromSQL(start);
            const f = DateTime.fromSQL(end)
            const { days } = f
                .plus({ days: 1 })
                .diff(i, "days")
                .toObject();
            return days;
        },
        _addMinutes(fecha, minutos = 1) {
            return DateTime
                .fromSQL(fecha)
                .plus({ minutes: minutos })
                .toFormat('yyyy-MM-dd HH:mm:ss');
        }
    }
}